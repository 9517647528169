<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import {
  linewithDataChart,
  dashedLineChart,
  splineAreaChart,
  columnChart,
  columnDatalabelChart,
  barChart,
  mixedChart,
  radialChart,
  pieChart,
  donutChart
} from "./data-apex";

/**
 * Apex-chart component
 */
export default {
  components: { Layout, PageHeader },
  data() {
    return {
      linewithDataChart: linewithDataChart,
      dashedLineChart: dashedLineChart,
      splineAreaChart: splineAreaChart,
      columnChart: columnChart,
      columnDatalabelChart: columnDatalabelChart,
      barChart: barChart,
      mixedChart: mixedChart,
      radialChart: radialChart,
      pieChart: pieChart,
      donutChart: donutChart,
      title: "Stats. sur les Alertes",
      items: [
        {
          text: "Statistiques",
          href: "/"
        },
        {
          text: "Alertes",
          active: true
        }
      ]
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    <!-- end row -->

    <!-- end row -->
    <div class="row">
        
          <div class="col-sm-4">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs me-3">
                    <span
                      class="
                        avatar-title
                        rounded-circle
                        bg-soft bg-primary
                        text-primary
                        font-size-18
                      "
                    >
                      <i class="bx bx-copy-alt"></i>
                    </span>
                  </div>
                  <h5 class="font-size-14 mb-0">Total alertes</h5>
                </div>
                <div class="text-muted mt-4">
                  <h4>
                    15.452
                    <i class="mdi mdi-chevron-up ms-1 text-success"></i>
                  </h4>
                  <div class="d-flex">
                    <span class="badge badge-soft-success font-size-12"
                      >+ 0.2%</span
                    >
                    <span class="ms-2 text-truncate">De l'année précedente</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-4">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs me-3">
                    <span
                      class="
                        avatar-title
                        rounded-circle
                        bg-soft bg-primary
                        text-primary
                        font-size-18
                      "
                    >
                      <i class="bx bx-archive-in"></i>
                    </span>
                  </div>
                  <h5 class="font-size-14 mb-0">Alertes traitées</h5>
                </div>
                <div class="text-muted mt-4">
                  <h4>
                     2.820
                    <i class="mdi mdi-chevron-up ms-1 text-success"></i>
                  </h4>
                  <div class="d-flex">
                    <span class="badge badge-soft-success font-size-12"
                      >+ 0.2%</span
                    >
                    <span class="ms-2 text-truncate">From previous period</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-4">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="avatar-xs me-3">
                    <span
                      class="
                        avatar-title
                        rounded-circle
                        bg-soft bg-primary
                        text-primary
                        font-size-18
                      "
                    >
                      <i class="bx bx-purchase-tag-alt"></i>
                    </span>
                  </div>
                  <h5 class="font-size-14 mb-0">Alertes non traitées</h5>
                </div>
                <div class="text-muted mt-4">
                  <h4>
                    145
                    <i class="mdi mdi-chevron-up ms-1 text-success"></i>
                  </h4>

                  <div class="d-flex">
                    <span class="badge badge-soft-warning font-size-12"
                      >0%</span
                    >
                    <span class="ms-2 text-truncate">From previous period</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

    <div class="row">
        <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Stats. Alertes</h4>
            <!-- Column Charts -->
            <apexchart
              class="apex-charts"
              height="350"
              type="bar"
              dir="ltr"
              :series="columnChart.series"
              :options="columnChart.chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Stats. Alertes</h4>
            <!-- Column with Data Labels -->
            <apexchart
              class="apex-charts"
              height="350"
              type="bar"
              dir="ltr"
              :series="columnDatalabelChart.series"
              :options="columnDatalabelChart.chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

   
  </Layout>
</template>